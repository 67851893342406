@import url(https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #fff;
}

ul {
  padding-left: 20px;
}

body {
  background: #c33d3c;
}

/*header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  background: #c33d3c;
}*/

.small-lines-decoration {
  position: absolute;
  top: -29px;
  left: 35%;
}

.section1 .header-outer {
  border-bottom: 2px rgba(255, 255, 255, 0.1) solid;
  position: relative;
  z-index: 2;
}

.empty::after {
  content: ".";
  visibility: hidden;
}

.section1 .black-lines-decoration {
  position: absolute;
  bottom: -1px;
  left: 20%;
  z-index: 2;
}

.black {
  color: #000;
}

.white {
  color: #fff;
}

h2 {
  text-transform: uppercase;
  font-family: "Monoton", cursive;
  font-size: 70px;
  color: #fff;
  font-weight: 400;
  margin: 0;
}

h3 {
  color: #fff;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 16px;
}

.splash {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  height: 100%;
}

.splash .columns {
  display: grid;
  grid-template-columns: 1fr auto;
}

.splash .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.splash .video {
  width: 608px;
  height: 342px;
}

/* Carousel */
section .carousel .slide {
  background: none;
}

section .carousel .control-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

section .carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  transition: 0.25s ease-in;
  margin: 8px;
}

section .carousel .control-dots .dot.selected {
  width: 14px;
  height: 14px;
}

/* Carousel arrows */
section .carousel.carousel-slider .control-arrow {
  z-index: 1;
}

section .carousel .control-arrow::before,
section .carousel.carousel-slider .control-arrow::before {
  border-top-width: 16px;
  border-bottom-width: 16px;
}
section .carousel .control-prev.control-arrow::before {
  border-right-width: 16px;
}
section .carousel .control-next.control-arrow::before {
  border-left-width: 16px;
}

#modal-container {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ReactModalPortal {
  position: relative;
  z-index: 999;
}

/* .ReactModal__Body--open #modal-container {
  display: block;
  background: #000;
  z-index: 999;
} */

